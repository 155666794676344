// Detect User
//import Detect from './utils/detect';

// UI Components/Modules - Essentials
import Scroll from './modules/components/scroll';

// UI Components/Modules - After Loaded
import Components from './modules/index';

// Store
 import { app } from './utils/store';

app.scroll = new Scroll();
app.scroll.init();


app.components = new Components();
app.components.init();
//Added by me

$(document).ready(function(){
    $(".show_hide").click(function(){
        $(".searchDiv").slideToggle("slow");
    });
    $( "#search-article" ).keyup(function() {
        var searchValue = $( "#search-article" ).val();
        var filterItems = $(".article-item");
        
            if ( searchValue != '' ) {
                filterItems.addClass('d-none');
                $('.article-item[data-title*="' + searchValue.toLowerCase() + '"]').removeClass('d-none');
                $('.article-item[data-code*="' + searchValue.toLowerCase() + '"]').removeClass('d-none');
                $('.article-item[data-author*="' + searchValue.toLowerCase() + '"]').removeClass('d-none');
            } else {
                filterItems.removeClass('d-none');
            }
      });
//Addition of classes for resolving submenu appearing on page reload
    $('#menu-toggler').on('click', function(){
        $('#main-menu').removeClass('d-none');
        $('#main-menu').addClass('d-inline-block');
    });
});
