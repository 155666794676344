// store
// import { app } from '../utils/store';
// import { page } from '../utils/store';

import Menu from '../modules/components/menu';
// import Modal from '../modules/components/modal';
import Carousels from '../modules/components/carousel';
// import Lightgallery from '../modules/components/lightgallery';
// import Gallery from '../modules/components/gallery';
 // import Filters from '../modules/components/filter';
 // import Gdpr from "./components/gdpr";
 // import ShowCatalogue from './components/showCatalogue';

export default class Components {
    constructor() {
        this.header = document.querySelector('header');
        this.modal = document.querySelector('[class*="modal"]');
        this.carousels = document.querySelector('.carousel');
        // this.lightgallery = document.querySelector('.lightgallery')
    }

    init(){
        if(this.header) {
            this.header = new Menu();
            this.header.init();
        }

        if(this.modal) {
            this.modal = new Modal();
            this.modal.init();
        }

        if(this.carousels) {
            this.carousels = new Carousels();
            this.carousels.init();
        }


        // this.animation = new Animation();
        // this.animation.init();
    }
}
