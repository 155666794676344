import $ from "jquery";
// import Sniffer from 'snifferjs';
import "slick-carousel";

export default class Carousels {
  constructor() {
    this.carousel = {
      //news: document.querySelector('.news-slider')
    };
  }

  init() {
    //if(this.carousel.news) { this.newsCarousel(); }
  }
}

$(".news-slider").each(function () {
  $(this)
    .not(".slick-initialized")
    .slick({
      dots: false,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: false,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        // {
        // 	breakpoint: 552,
        // 	settings: {
        // 		slidesToShow: 1,
        // 		slidesToScroll: 1
        // 	}
        // },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        // {
        // 	breakpoint: 393,
        // 	settings: {
        // 		slidesToShow: 1,
        // 		slidesToScroll: 1
        // 	}
        // },
        // {
        // 	breakpoint: 375,
        // 	settings: {
        // 		slidesToShow: 1,
        // 		slidesToScroll: 1
        // 	}
        // }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ],
    });
});

$(".gallery-slider").slick({
  arrows: true,
  dots: false,
  infinite: true,
  speed: 450,
  slidesToShow: 1,
  slidesToScroll: 1,
  mobileFirst: true,
  autoplay: true,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        centerMode: false,
        arrows: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        centerMode: false,
        arrows: true,
        dots: false,
      },
    },
  ],
});

$(".article-slider").slick({
  
  arrows: true,
  rows:1,
  prevArrow: '<div type="button" class="btn-prev"></div>',
  nextArrow: '<div type="button" class="btn-next"></div>',
  dots: false,
  infinite: false,
  speed: 450,
  slidesToShow: 1,
  slidesToScroll: 1,
  mobileFirst: true,
  autoplay: true,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        rows:1,
        centerMode: false,
        arrows: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        rows:2,
        slidesToShow: 3,
        centerMode: false,
        arrows: true,
        dots: false,
      },
    },
  ],
});
