import $ from "jquery";

export default class Menu {
    constructor(){
        this.header = document.querySelector('header');
        this.menu = document.getElementById('main-menu');
        this.sidebar = document.getElementById('sidebar');
    }

    init() {
        console.log('Menu.js - Exists');
        if(this.header){ this.animationMenu(); }
        if(this.sidebar){ this.toggleSidebar(); }
        if(this.menu){
            this.toggleMenu();
            // this.toggleSubMenu();
            //this.dropdownMenu();
            // console.log(Sniff.features.mobile)
        }
        // if(Sniff.features.mobile && document.body.classList.contains('business-unit')){
        //     console.log('Business Unit');
        //     this.businessUnitMobile();
        // }
    }
    //
    animationMenu(){
        $(window).on("scroll",function() {
            if($(this).scrollTop() > 0) {
                $("header").addClass("background-active fixed");
            }
            else{
                $("header").removeClass("background-active fixed");
            }
        })
    }
    //
    toggleMenu() {
        let root = document.documentElement;
        const header = $('header');
        const mainMenu = $('#main-menu');
        const menuToggler = $('#menu-toggler');
        const contactForm = $('#candidate-form');
        // const strenghtContent = $('.content-full');

        if(menuToggler.length > 0){
            //console.log('Bruna');
            root.style.setProperty('--mouseX', (menuToggler.offset().left + menuToggler.outerWidth()*0.5) + "px");
            root.style.setProperty('--mouseY', (menuToggler.offset().top + menuToggler.outerHeight()*0.5) + "px");

        }

        menuToggler.on('click', function(e){
                header.toggleClass('active');
                $("header").toggleClass("fixed");
                root.style.setProperty('--mouseX', e.clientX + "px");
                root.style.setProperty('--mouseY', e.clientY + "px");
                $('body').toggleClass('overflow-hidden');

        });
    }
    //
    // toggleSubMenu() {
    //     const mainMenu = $('#main-menu');
    //     var linkSubMenu = $('#main-menu .has-children');
    //
    //     linkSubMenu.each(function(){
    //         const dropdownBtn = "<span class='icon-plus'>+</span>";
    //         $(this).children('a')
    //             .attr('type', 'button')
    //             .attr('disabled', 'disabled')
    //             .removeAttr('href');
    //         $(this).children('a').append(dropdownBtn);
    //
    //         $(this).on('click', function(){
    //             const numb = $(this).find(".submenu li").length;
    //             const heightV = $(this).find(".submenu li").outerHeight();
    //
    //             if($(this).hasClass('active')){
    //                 mainMenu.removeClass('focused');
    //                 linkSubMenu.removeClass('active');
    //                 linkSubMenu.children(".submenu").height(0);
    //                 $(this).children(".submenu").height(0);
    //                 console.log('Dropdown Closed');
    //             } else {
    //                 mainMenu.addClass('focused');
    //                 linkSubMenu.removeClass('active');
    //                 linkSubMenu.children(".submenu").height(0);
    //                 $(this).addClass('active');
    //                 $(this).children(".submenu").height(heightV * numb);
    //                 console.log('Dropdown Open');
    //             }
    //         });
    //     });
    // }
    //
    // dropdownMenu() {
    //     const mainHeader = $('#main-header');
    //     var dropdownBtn = $('#main-header .dropdown-toggle');
    //
    //     dropdownBtn.each(function(){
    //         $(this).children('a')
    //             .attr('disabled', 'disabled')
    //             .removeAttr('href');
    //
    //         $(this).on('click', function(){
    //             var dataID = $(this).attr('id');
    //
    //             $(this).children('.dropdown-menu').toggleClass('show');
    //         });
    //     });
    // }
    //
    // toggleSidebar() {
    //     const sidebar = $('#sidebar');
    //     const sidebarToggler = $('#sidebar-toggler');
    //     const sidebarCloser = $('#sidebar-closer');
    //
    //     if(sidebar.length > 0){
    //
    //         sidebarToggler.on('click', function(e){
    //             sidebar.addClass('active');
    //             $('body').addClass('overflow-hidden');
    //         });
    //
    //         sidebarCloser.on('click', function(e){
    //             sidebar.removeClass('active');
    //             $('body').removeClass('overflow-hidden');
    //         });
    //     };
    // }
}
